<template>
  <div class="com-item">
    <div class="w">
      
      <div class="t2">
        <!-- 通向您的数字未来的钥匙 -->
        {{ $t("use.walletBox1T2") }}
      </div>
      <div class="p">
        <!-- 钱包能够访问您的资金和PSC应用。应当只有您才能访问您的钱包。 -->
        {{ $t("use.walletBox1P") }}
      </div>
      <div class="butBox">
        <a href="#wallet">
          <div class="but1">
            <!-- 获取钱包 -->
            {{ $t("use.walletBox1But") }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  height: 100vh;
  box-shadow: 0px 2px 8px 0px rgba(212, 217, 250, 0.5);
  background: linear-gradient(0deg, #dae6f2, #edf4fc);
  .w {
    // background-color: pink;
    height: 100vh;
    background-image: url("../../assets/images/v4/use/walletBox1.png");
    background-position: right, bottom;
    background-repeat: no-repeat;
    padding-top: 150px;
    box-sizing: border-box;
    .t1 {
      height: 29px;
      font-size: 30px;
      font-weight: 400;
      color: #333333;
    }
    .t2 {
      width: 797px;
      margin-top: 65px;
      height: 159px;
      font-size: 70px;
      font-weight: bold;
      color: #333333;
      line-height: 92px;
    }
    .p {
      margin-top: 23px;
      height: 66px;
      font-size: 24px;
      font-weight: 500;
      width: 619px;
      color: #333333;
    }
    .butBox {
      display: flex;
      margin-top: 81px;
      text-align: center;
      .but1 {
        display: inline-block;
        padding: 0 42px;
        height: 54px;
        line-height: 54px;
        background: linear-gradient(-90deg, #10c1fc, #3f65f9);
        box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
        border-radius: 27px;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        margin-right: 50px;
      }
    }
  }
}
</style>

<template>
  <div class="com-item">
    <div class="w">
      <!-- 有什么用 -->
      <div class="wl-title3">{{ $t("use.walletBox2T") }}</div>
      <div class="ul">
        <div
          class="wl-item2"
          v-for="(item, index) in $t('use.walletBox2List')"
          :key="index"
        >
          <div class="img">
            <img
              :src="
                require(`../../assets/images/v4/use/walletBox2${index + 1}.png`)
              "
              alt=""
            />
          </div>
          <div class="t">{{ item.title }}</div>
          <div class="link"></div>
          <div class="p">
            {{ item.text }}
          </div>
          <!-- <div class="more">
            <a href="javascript:;" @click="uti.goPath(item.url)">
              {{ item.ft }}
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-arrowRight"></use>
              </svg>
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  // height: 854px;
  padding-top: 146px;
  padding-bottom: 108px;
  box-sizing: border-box;
  background-image: url("../../assets/images/v4/com/box2BG.png");
  background-repeat: no-repeat;
  background-position: right 450px;
  .w {
    // background-color: pink;
    // height: 854px;
    .ul {
      margin-top: 100px;
      // background-color: pink;
      width: 100%;
      // height: 500px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .wl-item2 {
        width: 440px;
        // height: 470px;
      }
      .wl-item2:hover {
        background: #015eea;
        // background: linear-gradient(-45deg, #015eea, #00c0fa);
        color: #ffffff;
        // transform: translateY(-62px);
        margin-top: -81px;
        .t {
          color: #ffffff;
        }
        .link {
          background-color: #fff;
        }
        .android {
          color: #ffffff;
        }
        .p {
          color: #ffffff;
        }
        .more {
          color: #ffffff;
          a {
            color: #ffffff;
          }
        }
      }

      .wl-item2:hover::after {
        margin-top: 81px;
      }
    }
  }
}
</style>
